/**
 * @description: 百度热点
 */
import { ref } from "vue";
import { panel } from "@/api";

import { ElMessage } from 'element-plus'

let list = ref([]);
let load = ref(true);
const baiduHotspot = () => {
    panel.baiduHotspot().then((res) => {
        const { data, code } = res.data;
        if (code === 0) {
            list.value = [...data.splice(0, 4)];
        } else {
            ElMessage('加载失败了，奇怪🧐？')
            list.value = [];
        }
        load.value = false;
    }).catch(() => {
        load.value = false;
    });
};
export default () => {
    return {
        baiduHotspot,
        list,
        load,
    }
}