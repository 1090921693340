<template>
  <div class="movieInfo">
    <el-row class="pb-2 movieInfo_tit">
      <el-col class="movieInfo_tit-left" :span="12">
        <strong>豆</strong> <b>电影资讯</b>
      </el-col>
      <el-col class="movieInfo_tit-right" :span="12">
        <el-select
          size="mini"
          v-model="select"
          style="width: 100px"
          placeholder="请选择"
          @change="getList"
        >
          <el-option label="热门" value="热门"> </el-option>
          <el-option label="最新" value="最新"> </el-option>
          <el-option label="豆瓣高分" value="豆瓣高分"> </el-option>
          <el-option label="冷门佳片" value="冷门佳片"> </el-option>
          <el-option label="华语" value="华语"> </el-option>
          <el-option label="日语" value="日语"> </el-option>
          <el-option label="韩国" value="韩国"> </el-option>
        </el-select>
      </el-col>
    </el-row>
    <div>
      <el-skeleton :rows="5" :loading="loading" animated>
        <template #default>
          <!-- 轮播 -->
          <!-- <el-carousel :interval="4000" type="card" height="200px"> -->
          <el-carousel :interval="4000" height="300px" arrow="always">
            <el-carousel-item v-for="items in list" :key="items">
              <el-row :gutter="20" style="padding-top:30px;">
                <el-col
                  :span="sum == 3 ? 8 : 6"
                  v-for="ele in items"
                  :key="ele.id"
                >
                  <a :href="ele.url" target="__blank">
                    <div class="cover-wp">
                      <!-- <el-image :src="ele.cover" lazy></el-image> -->
                      <img :src="ele.cover" alt="" srcset="" />
                      <p>
                        {{ ele.title.substring(0, 10) }}
                        <strong>{{ ele.rate }}</strong>
                      </p>
                    </div>
                  </a>
                </el-col>
              </el-row>
            </el-carousel-item>
          </el-carousel>
        </template>
      </el-skeleton>
    </div>
  </div>
</template>

<script>
import { douban } from "@/api";
import { ref, onBeforeMount } from "vue";
export default {
  name: "movieInfo",
  setup() {
    let select = ref("热门");
    let loading = ref(true);
    let list = ref([]);
    let sum = ref(5);
    onBeforeMount(() => {
      getList();
    });
    // 加载列表
    function getList() {
      const params = {
        type: "movie",
        tag: select.value,
        page_limit: 36,
        page_start: 0,
      };
      loading.value = true;
      douban.search_subjects(params).then((res) => {
        const { subjects } = res.data;
        parseData(subjects);
      });
    }
    // 解析数据
    function parseData(data) {
      let page = [];
      // 检测当前屏幕宽度
      const wid = document.documentElement.clientWidth;

      wid < 500 ? (sum.value = 3) : (sum.value = 4);

      page = Array(sum.value).fill([]);

      const newlist = page.map(() => {
        return data.splice(0, sum.value);
      });
      list.value = [...newlist];
      loading.value = false;
    }
    return { list, sum, loading, select, getList };
  },
};
</script>

<style>
/* 标题 */

.movieInfo .movieInfo_tit strong {
  display: inline-block;
  padding: 3px;
  font-size: 12px;
  background-color: green;
  color: #fff;
}
.movieInfo .movieInfo_tit-right {
  text-align: right;
}
.movieInfo .movieInfo_tit b {
  font-size: 15px;
  font-weight: 900;
  color: rgb(30, 128, 255);
}
.el-carousel--horizontal {
  overflow-y: hidden;
}
/* 指示器位置覆盖 */
.movieInfo .el-carousel__indicators--horizontal {
  bottom: -10px !important;
}
.movieInfo .el-carousel__button {
  background-color: #9b9696 !important;
}
/* 切换键 覆盖 */
.movieInfo .el-carousel__arrow {
  background-color: rgb(235 239 245 / 80%) !important;
}
/* 元素 内部对齐方式 */
.movieInfo .cover-wp {
  text-align: center;
}
.movieInfo .cover-wp img {
  width: 90%;
  max-width: 116px;
  min-width: 90px;
}
.movieInfo .cover-wp p {
  line-height: 22px;
  font-size: 14px;
}
.movieInfo .cover-wp strong {
  color: #e09015;
}
</style>