<template>
  <el-card class="box-card yf_timer">
    <el-row>
      {{ year }}年{{ month }}月{{ day }}日 {{ hours }}时{{ minutes }}分{{
        seconds
      }}秒
    </el-row>
  </el-card>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUnmounted } from "vue";
let Time = null;
export default {
  name: "cardTime",
  setup() {
    let year = ref("0000");
    let month = ref("00");
    let day = ref("00");
    let hours = ref("00");
    let minutes = ref("00");
    let seconds = ref("00");

    onBeforeMount(() => {
      setTime();
    });
    onMounted(() => {
      Time = setInterval(setTime, 1000);
    });
    onUnmounted(() => {
      clearInterval(Time);
    });
    // 日期
    function setTime() {
      const date = new Date();
      [
        year.value,
        month.value,
        day.value,
        hours.value,
        minutes.value,
        seconds.value,
      ] = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ];
    }

    return {
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
    };
  },
};
</script>

<style>
.yf_timer div {
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
  text-shadow: -2px 2px 3px rgb(78, 77, 77，0.8),
    2px -2px 3px rgb(97, 96, 96，0.8);
  background-color: #4b7efe;
}
</style>