<template>
  <div class="yf_panel">
    <el-row :gutter="20">
      <el-col class="mb-2" :xs="24" :sm="24" :md="24">
        <card-time />
      </el-col>
      <el-col class="mb-2" :xs="24" :sm="12" :md="12">
        <el-card class="box-card yf_future">
          <div style="height: 143px">
            <el-row class="yf_search_box">
              <el-input
                size="small"
                placeholder="请输入内容"
                v-model="input"
                @change="handleSearch"
                class="input-with-select"
              >
                <template #prepend>
                  <el-select
                    size="small"
                    style="width: 80px"
                    v-model="select"
                    placeholder="请选择"
                  >
                    <el-option label="百度" value="1"></el-option>
                    <el-option label="谷歌" value="2"></el-option>
                  </el-select>
                </template>
                <template #append>
                  <el-button
                    size="small"
                    style="width: 60px"
                    type="primary"
                    @click="handleSearch"
                    icon="el-icon-search"
                  ></el-button>
                </template>
              </el-input>
            </el-row>
            <!-- 热点 -->
            <div style="margin: 5px 0" align="right">
              <el-tag type="warning" size="mini"> 百度实时热点 </el-tag>
            </div>
            <div class="baidu_hotspot" v-if="list.length != 0">
              <el-scrollbar height="200px">
                <p class="item" v-for="(item, index) in list" :key="item.title">
                  <el-link :href="item.href" target="_blank">
                    <span>{{ ++index }}、 </span>{{ item.title }}</el-link
                  >
                </p>
              </el-scrollbar>
            </div>
            <!-- 暂无数据 -->
            <div
              style="color: #8e8b8bde; font-size: 15px"
              v-show="list.length == 0"
            >
              热点数据为空
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col class="mb-2" :xs="24" :sm="12" :md="12">
        <el-card class="box-card">
          <weather />
        </el-card>
      </el-col>

      <el-col class="mb-2" :xs="24" :sm="24" :md="12">
        <el-card class="box-card">
          <real-time-info />
        </el-card>
      </el-col>
      <el-col class="mb-2" :xs="24" :sm="24" :md="12">
        <el-card class="box-card">
          <movie-info />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import sikao from "@/assets/img/panel/sikao.webp";
import rensheng from "@/assets/img/panel/rensheng.jpg";
import cardTime from "@/components/panel/cardTime";
import realTimeInfo from "@/components/panel/realTimeInfo";
import movieInfo from "@/components/panel/movieInfo";
import weather from "@/components/panel/weather";

import baidu from "./composition/panel/baidu";

import { ref, onBeforeMount } from "vue";
export default {
  name: "panel",
  components: { cardTime, realTimeInfo, movieInfo, weather },
  setup() {
    const select = ref("1");
    const input = ref("");
    // 更新
    const handleSearch = () => {
      let key = input.value.trim();
      let ck = select.value;
      if (key != "") {
        if (ck == 1) {
          window.open(`https://www.baidu.com/s?wd=${key}`, "_blank");
        } else if (ck == 2) {
          window.open(`https://www.google.com.hk/search?q=${key}`, "_blank");
        }
      }
    };

    // 加载热点
    let { baiduHotspot, list, load } = baidu();

    onBeforeMount(() => {
      baiduHotspot();
    });
    return {
      input,
      sikao,
      select,
      rensheng,
      handleSearch,
      list,
      load,
    };
  },
};
</script>

<style>
/* 未来 */
.yf_future {
  text-align: center;
}
.yf_future .futrue_tit {
  text-align: left;
  font-size: 18px;
  font-weight: 900;
  background-image: linear-gradient(to right, green, yellow);
  background-clip: text;
  text-fill-color: transparent;
}
.yf_future img {
  width: 100%;
  max-width: 116px;
}
/* 搜索logo */
.search_logo {
  background: #4b7efe;
  width: 152px;
  margin: auto;
  border-radius: 3px;
  margin-bottom: 15px;
}
.search_logo img {
  transform: translateY(-4px);
}
.yf_search_box .el-input-group__append,
.el-input-group__prepend {
  background-color: #1e80ff;
  color: #fff;
}
/* 百度热点 */
.baidu_hotspot .item {
  text-align: left;
  line-height: 22px;
}
.baidu_hotspot .item a {
  font-size: 13px;
}
</style>