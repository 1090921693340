<template>
  <div class="weather_container">
    <el-skeleton :rows="3" :loading="loading" animated>
      <template #default>
        <el-descriptions title="天气信息" :column="2">
          <el-descriptions-item label="地区" min-width="200">{{
            info.city
          }}</el-descriptions-item>
          <el-descriptions-item label="天气">
            <img
              style="width: 20px; vertical-align: middle"
              :src="`http://meteor.ckcest.cn/liveIcon/${info.icon}.png`"
              alt=""
            />
            <el-tag size="small">{{ info.condition }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="温度" min-width="200">
            <el-tag size="small" type="success">{{ info.temp }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="风向">
            {{ info.windDir }}</el-descriptions-item
          >
          <el-descriptions-item label="湿度" min-width="200">
            {{ info.humidity }}</el-descriptions-item
          >
          <el-descriptions-item span="1" label="提示">
            {{ info.tips }}</el-descriptions-item
          >
        </el-descriptions>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import { tianqi } from "@/api";
import { onBeforeMount, ref } from "vue";
export default {
  name: "weeather",
  setup() {
    const loading = ref(true);
    const city = localStorage.getItem("mycity");
    let info = ref({
      city, //地区
      condition: "--", //情况
      icon: "1", //图标
      tips: "--", //提示
      temp: "--", //温度
      windDir: "--", //风向
      humidity: "--", //湿度
    });

    onBeforeMount(() => {
      getStationID();
    });
    // 维度
    const getStationID = async () => {
      const {
        data: {
          result: { V05001, V06001 },
        },
      } = await tianqi.getStationID({ city });
      const {
        data: {
          result: {
            data: {
              condition: { condition, icon, tips, temp, windDir, humidity },
            },
          },
        },
      } = await tianqi.live({ lat: V05001, lon: V06001, type: "3" });
      info.value = {
        ...info.value,
        condition,
        icon,
        tips,
        temp,
        windDir,
        humidity,
      };
      loading.value = false;
    };

    return { info,loading };
  },
};
</script>

<style>
.weather_container .el-descriptions {
  font-size: 12px !important;
}
</style>