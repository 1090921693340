<template>
  <el-row class="real_time-top">
    <el-col class="real_t-left" :span="12">
      <img
        src="https://lf1-cdn-tos.bytescm.com/obj/static/xitu_extension/static/gold.981a5510.svg"
        alt=""
      />掘金
    </el-col>
    <el-col class="real_t-right" :span="12">
      <el-select
        size="mini"
        v-model="select"
        style="width: 100px"
        placeholder="请选择"
        @change="handleChang"
      >
        <el-option label="前端" value="frontend"> </el-option>
        <el-option label="后端" value="backend"> </el-option>
        <el-option label="andorid" value="android"> </el-option>
        <el-option label="ios" value="ios"> </el-option>
        <el-option label="开发工具" value="freebie"> </el-option>
        <el-option label="代码人生" value="career"> </el-option>
      </el-select>
    </el-col>
  </el-row>
  <el-scrollbar max-height="300px">
    <el-skeleton :rows="5" :loading="loading" animated>
      <template #default>
        <div class="real_time-info" v-for="item in list" :key="item.id">
          <a :href="item.url" target="__blank">
            <h5>{{ item.title }}</h5>
            <div class="real_time-tag">
              <span v-for="tag in item.tags" :key="tag.id"
                >#{{ tag.tag_name }}</span
              >
              <span class="real_time-date">{{
                item.date.iso.substring(0, 10)
              }}</span>
              <span class="real_time-author">{{ item.user.username }}</span>
            </div>
          </a>
        </div>
      </template>
    </el-skeleton>
  </el-scrollbar>
</template>

<script>
import { juejin } from "@/api";
import { ref, onBeforeMount } from "vue";
import {elOption,elSelect} from 'element-plus'
export default {
  components:{elOption,elSelect},
  name: "realTimeInfo",
  setup() {
    let loading = ref(true);
    let list = ref([]); //列表
    let select = ref("frontend"); //标签

    onBeforeMount(() => {
      handleChang(); //初始化列表
    });

    // 标签选择
    function handleChang() {
      const params = {
        category: select.value,
        limit: 30,
        offset: 0,
        order: "heat",
      };
      loading.value = true;
      juejin.gold(params).then((result) => {
        const { code, data } = result.data;
        if (code == 200) {
          list.value = data;
          if (loading.value) {
            loading.value = false;
          }
        }
      });
    }

    return {
      list,
      select,
      loading,
      handleChang,
    };
  },
};
</script>

<style>
/* 资讯 标题*/

.real_time-top {
  padding: 10px 0;
}
.real_time-top .real_t-left {
  font-size: 14px;
  color: #1e80ff;
  font-weight: 800;
}

.real_time-top .real_t-left img {
  vertical-align: middle;
  width: 20px;
  margin-right: 5px;
}
.real_time-top .real_t-right {
  text-align: right;
}

/* 资讯 列表*/

.real_time-info {
  padding: 5px 8px;
}
.real_time-info h5 {
  line-height: 25px;
  font-size: 14px;
  color: #1d2129;
  word-wrap:break-word;
}
.real_time-info:hover {
  background-color: #e8f3ff;
}

.real_time-info:hover h5 {
  color: rgb(30, 128, 255);
}

.real_time-tag {
  line-height: 24px;
  font-size: 12px;
  color: #86909c;
}
.real_time-tag span {
  margin-right: 5px;
}
.real_time-tag .real_time-author {
  float: right;
}
.real_time-tag .real_time-date {
  float: right;
}
</style>